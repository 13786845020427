.bg-aliceblue {
  background-color:aliceblue;
}

.bg-facebook {
  background-color:#285da1;
}

.bg-twitter {
  background-color:#03a9f4;
}

.bg-youtube {
  background-color: #ff0000;
}

.bg-instagram {
  background-color: #E4405F;
}

.bg-gmail {
  background-color: #c71610;
}

.footer__widget-social {
    display: inline-block;
    width: 44px;
    height: 44px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    font-size: 22px;
}

.category__item {
  padding: 40px;
    box-shadow: 0 30px 70px 0 rgb(8 0 42 / 10%);
    border-radius: 10px;
}

.category__item:hover {
  background-color: aliceblue;
}

.reference-list-wrapper{
  overflow: auto;
  white-space: nowrap;
}

.reference-list{
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.reference-list:hover{
  opacity:0.6;
}

.serviceImg{
  max-width: 600px !important;
  height: auto !important
}